import { Link } from "react-router-dom";

function Header(props) {

    const clientLogout = event => {
        localStorage.clear();
        window.location.reload();
    }

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link toggle-sidebar" data-widget="pushmenu" href="/" role="button">
                        <i className="fas fa-bars"></i>
                    </a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link to="/" className="nav-link link">Главная</Link>
                </li>
            </ul>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                    <a className="d-flex" data-toggle="dropdown" href="/" aria-expanded="false">
                        <div className="custom-nav-avatar">
                            <img src={props.instructor.photo} alt=""/>
                        </div>
                        <div className="user-info ml-2">
                            <div className="user-info-names">{props.instructor.lastname} {props.instructor.name} {props.instructor.patronymic}</div>
                            <div className="user-info-phone">{props.instructor.phoneFormat}</div>
                        </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <div className="avatar text-center mt-2 position-relative">
                            <img src={props.instructor.photo} alt=""/>
                        </div>
                        <span className="d-block text-bold text-center mt-2 mb-2">{props.instructor.lastname} {props.instructor.name} </span>
                        <span className="d-block text-center mt-2 mb-2">{props.instructor.phoneFormat}</span>
                        <a href="/" className="dropdown-item position-relative" onClick={clientLogout}>
                            <i className="far fa-times-circle mr-2"></i> Выйти из системы
                        </a>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default Header;