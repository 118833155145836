import React, {useEffect, useState} from 'react';
import Header from './components/Main/Header';
import Sidebar from './components/Main/Sidebar';
import Content from './components/Main/Content';
import Login from "./components/Main/Login";

function App() {
    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;
    const auth = JSON.parse(localStorage.getItem('auth'));
    const [school, setSchool] = useState([]);
    const [instructor, setInstructor] = useState([]);

    useEffect(() => {
        if (!auth) {
            return;
        }

        const data = {
            path: 'instructor/info',
            school_id: auth.school_id,
            instructor_id: auth.instructor_id
        };

        fetch(API_SCRIPT, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(res => res.json()).then((response) => {
            if (response.status === 'done') {
                setInstructor(response.instructor);
                setSchool(response.school);

                auth.type = response.school.tariffType;
                localStorage.setItem('auth', JSON.stringify(auth));

                if (auth.type === 'pdd') {
                    localStorage.clear();
                    window.location.reload();
                }
            }
        });

        const rememberTime = () => {
            const date = Math.round(Date.now() / 1000);
            const login = auth.login;
            const remember = auth.remember === 1 ? 604800 : 3800;

            if (date > (+login + +remember)) {
                localStorage.clear();
                window.location.reload();
            }
        };

        rememberTime();
        setInterval(rememberTime, 10000);
    }, []);

    if (!auth) {
        return <Login />
    }

    return (
        <div className="wrapper">
            <Header school={school} instructor={instructor} auth={auth}/>
            <Sidebar school={school} auth={auth} />
            <Content school={school} instructor={instructor} auth={auth} />
        </div>
    );
}

export default App;
