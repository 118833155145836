import {Route, Routes} from "react-router-dom";
import Home from "../Pages/Home";
import Graphics from "../Content/Practice/Graphics";
import Clients from "../Content/Clients/Clients";

const Content = (props) => {
    return (
        <div className="content-wrapper position-relative">
            <Routes>
                <Route path="/" exact element={<Home school={props.school} instructor={props.instructor} auth={props.auth} />}/>
                <Route path="/practice/graphics" element={<Graphics school={props.school} instructor={props.instructor} auth={props.auth} />}/>
                <Route path="/clients" element={<Clients school={props.school} instructor={props.instructor} auth={props.auth} />}/>
            </Routes>
        </div>
    )
}

export default Content;