import React, { useState, useEffect } from "react";
import CloseSidebar from "../../Main/CloseSidebar";
import axios from "axios";

function Clients(props) {
    CloseSidebar();

    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;
    const [isLoaded, setIsLoaded] = useState(false);
    const [clients, setClients] = useState([]);
    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    // обновлялка времени
    useEffect(() => {
        setIsLoaded(true);
        setClients([]);

        const data = {
            path: 'instructor/clients',
            school_id: props.auth.school_id,
            instructor_id: props.auth.instructor_id
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;
            setIsLoaded(false);
            if (response.status === 'done') {
                setClients(response.clients);
            }
        })
    }, []);

    const itemsElements = clients.map((client, index) => {
        return (
            <div className="practice-graphics-item bg-white p-2" key={index}>
                <div className="d-flex justify-content-between">
                    <div className="text-bold">{ client.lastname }</div>
                    <div className="">{ client.progress }</div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="">{ client.name } { client.patronymic }</div>
                    <a href={'tel:' + client.phone} className="p-1 mr-2">
                        <i className="fas fa-phone"></i>
                    </a>
                </div>
            </div>
        )
    });

    return (
        <React.Fragment>
            <div className="content">
                <div className="practice-graphics-page position-relative">
                    <div className="practice-graphics-block">
                        { isLoaded === false ? (
                            <div className="practice-graphics-times custom-scroll">
                                <div className="text-bold">Список учеников ({ itemsElements.length })</div>
                                {itemsElements.length !== 0 ? (
                                    itemsElements
                                ) : (
                                    <div className="text-center text-bold mt-3">Ученики не найдены!</div>
                                )}
                            </div>
                        ) : (
                            <div className="loader">
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                                <div className="circle"></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Clients;